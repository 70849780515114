import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
import { Footer, Main, Navbar } from "../components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import rightIcon from "../assets/images/angle-left-solid.svg"; // Add your right icon image
import leftIcon from "../assets/images/angle-right-solid.svg"; // Add your left icon image
const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const dispatch = useDispatch();
  const [dimension, setDimension] = useState("");

  const handleButtonClick = (size) => {
    setDimension(size);
  };

  const addProduct = (product) => {
    const productWithDimension = {
      ...product,
      selectedDimension: dimension,
    };
    dispatch(addCart(productWithDimension));
    toast.success("Maxsulot muvaffaqiyatli qo'shildi", {
      position: "bottom-right",
      autoClose: 2000,
    });
  };

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      const currentLanguage = i18n.language;
      const response = await fetch(
        `https://ezithrds.uz/api/products/${id}?lang=${currentLanguage}`
      );
      const data = await response.json();
      setProduct(data);
      setLoading(false);
    };

    getProduct();

    const handleLanguageChange = () => {
      getProduct();
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [id]);

  const handleThumbnailClick = (image, index) => {
    setActiveIndex(index);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % product.photos.length);
  };

  const handlePrev = () => {
    setActiveIndex(
      (prevIndex) =>
        (prevIndex - 1 + product.photos.length) % product.photos.length
    );
  };

  const Loading = () => (
    <div className="container my-5 py-2">
      <div className="row">
        <div className="col-md-6 py-3">
          <Skeleton height={400} width={400} />
        </div>
        <div className="col-md-6 py-5">
          <Skeleton height={30} width={250} />
          <Skeleton height={90} />
          <Skeleton height={40} width={70} />
          <Skeleton height={50} width={110} />
          <Skeleton height={120} />
          <Skeleton height={40} width={110} inline={true} />
          <Skeleton className="mx-3" height={40} width={110} />
        </div>
      </div>
    </div>
  );

  const calculateDiscount = (price, discount) => {
    return price * (1 - discount / 100);
  };

  const ShowProduct = () => {
    const { t } = useTranslation();

    return (
      <div className="product-detail discount style-grouped">
        <div className="featured-product underwear filter-product-img pt-7 md:pb-20 pb-10">
          <div className="container flex justify-between gap-y-6 flex-wrap">
            <div className="list-img md:w-1/2 md:pr-[45px] w-full flex-shrink-0">
              <div className="sticky">
                <div className="swiper mySwiper2 rounded-2xl overflow-hidden swiper-initialized swiper-horizontal swiper-backface-hidden">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide popup-link" id="relative">
                      <Zoom>
                        <img
                          src={`https://ezithrds.uz/uploads/products/${
                            product.photos ? product.photos[activeIndex] : ""
                          }`}
                          alt="img"
                          className="w-full aspect-[3/4] object-cover main_img"
                        />
                      </Zoom>
                      <div
                        className="swiper-navigation"
                        id="wrapper_navigation_controller"
                      >
                        <button
                          className="swiper-button-prev"
                          onClick={handlePrev}
                        >
                          <img src={rightIcon} alt="" />
                        </button>
                        <button
                          className="swiper-button-next"
                          onClick={handleNext}
                        >
                          <img src={leftIcon} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <span
                    className="swiper-notification"
                    aria-live="assertive"
                    aria-atomic="true"
                  ></span>
                </div>
                <div className="swiper mySwiper swiper-initialized swiper-horizontal swiper-watch-progress swiper-backface-hidden swiper-thumbs">
                  <div className="swiper-wrapper">
                    {(product.photos ? product.photos : []).map(
                      (thumb, index) => (
                        <div
                          key={index}
                          className={`swiper-slide img_hero_items ${
                            index === activeIndex
                              ? "swiper-slide-thumb-active"
                              : ""
                          }`}
                          onClick={() => handleThumbnailClick(thumb, index)}
                        >
                          <img
                            src={`https://ezithrds.uz/uploads/products/${thumb}`}
                            alt={`thumbnail-${index}`}
                          />
                        </div>
                      )
                    )}
                  </div>
                  <span
                    className="swiper-notification"
                    aria-live="assertive"
                    aria-atomic="true"
                  ></span>
                </div>
              </div>
            </div>
            <div
              className="product-infor md:w-1/2 w-full lg:pl-[15px] md:pl-2"
              data-item="1"
            >
              <div className="sticky">
                <div className="flex justify-between">
                  <div>
                    <div className="product-name heading4 mt-1">
                      {product.name}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-3 flex-wrap sm:mt-1 mt-5 pb-6 border-b border-line">
                  <div className="product-price heading5">
                    {product.discount
                      ? calculateDiscount(product.price, product.discount)
                      : product.price}{" "}
                    UZS
                  </div>
                  <div className="w-px h-4 bg-line"></div>
                  {product.discount && (
                    <>
                      <div className="product-origin-price font-normal text-secondary2">
                        <del>{product.price}</del>
                      </div>

                      <div className="product-sale caption2 font-semibold bg-green px-3 py-0.5 inline-block rounded-full">
                        -{product.discount}%
                      </div>
                    </>
                  )}
                </div>
                <div className="get-it mt-6">
                  <div className="heading5">{t("data")}</div>
                  <div className="item flex items-center gap-3 mt-4">
                    <div className="icon-delivery-truck text-4xl"></div>
                    <div>
                      <div className="text-title">{t("country")}</div>
                      <div className="caption1 text-secondary mt-1">
                        {product.country}
                      </div>
                    </div>
                  </div>
                  <div className="item flex items-center gap-3 mt-4">
                    <div className="icon-phone-call text-4xl"></div>
                    <div>
                      <div className="text-title">{t("made")}</div>
                      <div className="caption1 text-secondary mt-1">
                        {product.made_with}
                      </div>
                    </div>
                  </div>
                  <div className="item flex items-center gap-3 mt-4">
                    <div className="icon-return text-4xl"></div>
                    <div>
                      <div className="text-title">
                        {t("size")}:
                        <div className="caption1 text-secondary mt-1 size_finder">
                          {(product.dimensions ? product.dimensions : []).map(
                            (d, i) => (
                              <button
                                key={`dimension-${i}`}
                                className={
                                  dimension === d.value ? "active" : ""
                                }
                                onClick={() => handleButtonClick(d.value)}
                              >
                                {d.value}
                              </button>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="list-action mt-6">
                  <div className="choose-quantity flex items-center max-xl:flex-wrap lg:justify-between gap-5 mt-3">
                    <div
                      className="add-cart-btn button-main whitespace-nowrap w-full text-center text-white border border-black"
                      onClick={() => addProduct(product)}
                    >
                      {t("addproduct")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar />
      <Main />
      <div>
        <div>{loading ? <Loading /> : <ShowProduct />}</div>
        <div className="row my-5 py-5">
          <div className="d-none d-md-block"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;
