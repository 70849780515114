import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(i18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    lng: 'ru',
    debug: false,
    interpolation: {
      escapeValue: false
    },
    resources: {
      uz: {
        translation: {
          home: 'Bosh sahifa',
          collections:"Kolleksiya",
          about: 'Brend haqida',
          product: 'Mahsulotlar',
          tshirt: "Futbolkalar",
          pname: "Mahsulot nomi",
          country: "Ishlab chiqarish",
          made: "Material",
          sum: "So'm",
          addproduct: "Savatchaga qo'shish",
          buy: 'Sotib olish',
          size: "O'chami",
          data: "Ma'lumot",
          status: 'Yangi',
          buyurtma: 'Buyurtma berish',
          jami: 'Jami',
          deliver: 'Yetkazib berish',
          buyy: 'Xarid qilish',
          mahsulot: "Mahsulotingz",
          phone: 'Telefon raqamingz',
          fish: "F.I.SH",
          more: 'Batafsil',
          details: "Biz sizga har kuni kiya oladigan, qulay va nafis kiyimlarni taqdim etamiz",
          cart: "Sizning savatingiz bo'sh",
          continue: "Xaridni davom ettirish",
          noitems: "Xaridingiz uchun raxmat",
          priceName: "So'm",
          dl1: "O'zbekiston bo'ylab yetkazib beriladi",
          dl2: "Ikki yoki undan ortiq mahsulotga buyurtma bersangiz, yetkazib berish bepul",
          dl3: "Biz bilan bog'laning",
          dd1: "Telegram,",
          dd2: " Instagram, ",
          dl4: " +998333582929",
          call: "Tel",
          dl5: "Buyurtmani naqd pulda yoki xizmat orqali qabul qilgandan keyin to'lash",
          aboutInfo1: "EZITHRDS dunyosiga xush kelibsiz, bu yerda sifat, uslub va yengillik har bir narsada birlashadi.",
          aboutInfo2: "Bizning brendimiz Tantana Tekstile muvaffaqiyatli korxonasi asosida o'sib chiqdi va biz uslub va qulaylikni birlashtiruvchi kiyimlar yaratishdan faxrlanamiz.",
          aboutInfo3: 'EZITHRDS oddiy kiyimdan ko\'proqdir.Bu "Ease is Key" shiorimizda aks etgan yengillik falsafasidir.',
          aboutInfo4: "Biz ichki yengillikni topish va uni hayotning turli jabhalarida qo'llash orqali katta muvaffaqiyatlar va uyg'unlikka erishish mumkinligiga ishonamiz. Aynan shu g'oya bizning barcha mahsulotlarimiz asosida yotadi.",
          aboutInfo5: "Tantana Tekstile merosimiz bizga yuqori sifat an'analarini innovatsiyalar va zamonaviy tendentsiyalar bilan birlashtirish imkonini beradi. Biz har bir ishlab chiqarish bosqichini diqqat bilan nazorat qilamiz, shunda har bir EZITHRDS futbolkasi mukammallikka intilishimizning ifodasi bo'ladi. Bizning kolleksiyalarimizda har qanday holatga mos keladigan turli dizaynlar va o'lchamlarni topasiz.",
          aboutInfo6: "EZITHRDS hamjamiyatiga qo'shiling va yengillik sizning hayotiy g'alabalaringiz kalitiga aylanishini his eting. Kiyimlarimizning har bir tolasi sizning erkinligingiz va muvaffaqiyatingiz ramzi bo'lsin."

        },
        dimensions: "O'lcham",
        orderSuccess: 'Xaridingiz uchun raxmat'
      },
      ru: {
        translation: {
          home: 'Главная',
          about: 'О бренде',
          product: 'Товары',
          tshirt: "Футболки",
          pname: "Название продукта",
          country: "Страна производства",
          made: "Mатериал",
          sum: "Сумма",
          collections:"Коллекция",
          addproduct: 'Добавить в корзину',
          buy: 'Купить',
          size: "Размер",
          data: "Информация",
          status: 'New',
          buyurtma: 'Заказать',
          jami: 'Итого',
          count_total: 'Cумма заказа',
          deliver: 'Доставка',
          buyy: 'Заказать',
          buyy: 'Заказать',
          mahsulot: 'Ваш продукт',
          phone: 'Телефон',
          fish: "Ф.И.Ш",
          priceName: "Cум",
          more: 'Подробнее',
          details: "Мы предлагаем вам самые выгодные и надежные вещи, которые вы можете приобрести в нашем магазине",
          cart: "Ваша корзина пуста",
          continue: "Продолжить покупки",
          noitems: "Спасибо за заказ",
          dl1: "Доставка осуществляется по всему Узбекистану",
          dl2: "При заказе от двух товаров и более, доставка бесплатная",
          dl3: "Связаться с нами можно через",
          dd1: "Телеграмм,",
          dd2: " Инстаграмм, ",
          dl4: "позвонив нам по номеру +998333582929",
          dl5: "Оплата при получении заказа наличными, либо через сервис",
          call: "Тел",
          aboutInfo1: "Добро пожаловать в мир EZITHRDS, где качество, стиль и легкость соединяются в каждой вещи.",
          aboutInfo2: "Наш бренд вырос на основе успешного предприятия Tantana Tekstile, и мы гордимся тем, что создаем одежду, которая объединяет стиль и комфорт.",
          aboutInfo3: 'EZITHRDS – это больше, чем просто одежда. Это философия легкости, которая отражается в нашем слогане "Ease is Key". ',
          aboutInfo4: "Мы верим, что, найдя внутреннюю легкость и применяя её в различных аспектах жизни, можно достичь больших успехов и гармонии. Именно эта идея лежит в основе всех наших продуктов.",
          aboutInfo5: 'Наше наследие от Tantana Tekstile позволяет нам сочетать традиции высокого качества с инновациями и современными тенденциями. Мы тщательно контролируем каждый этап производства, чтобы каждая футболка EZITHRDS была воплощением нашего стремления к совершенству. В наших коллекциях вы найдете разнообразие дизайнов и размеров, идеально подходящих для любого случая.',
          aboutInfo6: "Присоединяйтесь к сообществу EZITHRDS и ощутите, как легкость может стать ключом к вашим жизненным победам. Пусть каждая нить нашей одежды символизирует вашу свободу и успех.",
          dimensions: "Размер",
          orderSuccess: "Спасибо за покупку"
        }
      },
      en: {
        translation: {
          home: 'HomePage',
          about: 'About us',
          product: 'Products',
          tshirt: "T-Shirt",
          pname: "Product Name",
          country: "Producer Country",
          made: "Made in",
          sum: "Sum",
          addproduct: 'Add Product',
          buy: 'Buy',
          priceName: "Price",
          size: "Size",
          data: "Information",
          status: 'New',
          buyurtma: 'Order',
          jami: 'Order Total amount',
          deliver: 'Delivery',
          buyy: 'Buy',
          mahsulot: 'Your Product',
          phone: 'Phone',
          fish: "Full Name",
          more: 'More',
          details: "We offer you the best and reliable items you can purchase in our store",
          cart: "Your Cart is Empty",
          continue: "Continue Shopping",
          noitems: "Thanks for shopping",
          dl1: "Delivery is carried out throughout Uzbekistan",
          dl2: "When ordering two or more items, delivery is free",
          dl3: "Contact us:",
          dd1: "Telegram,",
          dd2: " Instagram, ",
          dl4: "by calling us at +998333582929",
          call: "Call",
          dimensions: "O'lcham",
          dl5: "Payment upon receipt of the order in cash or through the service",
          aboutInfo: "Welcome to the world of EZITHRDS, where quality, style and ease are combined in every item. Our brand grew out of the successful Tantana Tekstile enterprise and we pride ourselves on creating clothes that combine style and comfort. EZITHRDS is more than just clothes . This is the philosophy of ease, which is reflected in our slogan 'Ease is Key'. We believe that by finding inner lightness and applying it in various aspects of life, you can achieve great success and harmony. It is this idea that underlies all our products. Our heritage from Tantana Tekstile allows us to combine tradition of high quality with innovation and modern trends. We carefully monitor every stage of production to ensure that every EZITHRDS T-shirt is the embodiment of our commitment to excellence. Our collections come in a variety of designs and sizes, perfect for any occasion. Join the EZITHRDS community and experience how lightness can be the key to your victories in life. Let every thread of our clothing symbolize your freedom and success."
        }
      }
    }
  });

export default i18n;
