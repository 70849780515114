import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '../assets/css/icomoon/ph--magnifying-glass.svg';
import Cart from './Cart';
import CloseBtn from "../assets/css/icomoon/ph--x.svg";
import ModalCart from './ModalCart';

export default function OrderList({ search, closeModal }) {
    const [searchQuery, setSearchQuery] = useState('');
    const modalRef = useRef(null);

    const handleSearchInput = (e) => {
        setSearchQuery(e.target.value);
    };



    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal();
        }
    };

    useEffect(() => {
        if (search) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [search]);

    return (
        <>
            <div className={`modal-search-block ${search ? 'open-search' : ''}`}>
                <div className={`modal-search-main md:p-10 p-6 rounded-[32px] ${search ? 'open-search' : ""}`} ref={modalRef}>
                    <img src={CloseBtn} alt="close" className="closeBtn" onClick={closeModal} />
                    <ModalCart />
                </div>
            </div>
        </>
    );
}
