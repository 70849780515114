import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles/main.css';
import './assets/scss/product.scss';
import './assets/scss/header.scss';
import './assets/scss/blog.scss';
import './assets/scss/footer.scss';
import './assets/scss/globals.scss';
import './assets/scss/modal.scss';
import './assets/scss/faqs.scss';
import './assets/scss/scroll-to-top.scss';
import './assets/scss/layout/text-has-line-before.scss';
import './assets/scss/layout/banner.scss';
import './assets/scss/layout/featured-product.scss';
import './assets/scss/layout/collection.scss';
import './assets/scss/layout/benefit.scss';
import './assets/scss/layout/tab-features.scss';
import './assets/scss/compare.scss';
import './assets/scss/cart.scss';
import './assets/scss/faqs.scss';
import './assets/scss/slider.scss';
import './assets/scss/layout/featured-product.scss';
import './assets/scss/layout/testimonial.scss';
import './assets/scss/shop.scss';
import './assets/output-tailwind.css';
import './styles/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';

import { Home, Product, Products, AboutPage, ContactPage, Cart, Login, Register, Checkout, PageNotFound } from "./pages"
import ScrollToTop from './hooks/ScrollToTop';
// lang 
import './i18.js';
import OrderList from './pages/OrderList.jsx';
import ModalCart from './pages/ModalCart.jsx';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Products />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/orderlist" element={<OrderList />} />
        <Route path="/modalcart" element={<ModalCart />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/product/*" element={<PageNotFound />} />
      </Routes>
    </Provider>
  </BrowserRouter>
);
