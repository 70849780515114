import React, { useState, useRef } from "react";
import { Footer, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { clearCart } from "../redux/action"; // Import the clearCart action

const Checkout = () => {
  const products = useSelector((state) => state.handleCart);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [orderPlaced, setOrderPlaced] = useState(false); // State to track if order is placed

  const clearCartHandler = () => {
    dispatch(clearCart());
  };

  const EmptyCart = () => {
    return (
      <div className="container mt-5" id="empty_cart">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5">{t("noitems")}</h4>
            <Link to="/" className="btn btn-outline-dark mx-4">
              <i className="fa fa-arrow-left"></i> {t("continue")}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const calculatePrice = ({ price, discount, qty }) => {
    const discounted = discount ? price * (1 - discount / 100) : price;
    const qtyExist = qty ?? 1;
    return discounted * qtyExist;
  };

  const calculateTotalPrice = () => {
    return products.reduce(
      (acc, product) => (acc += calculatePrice(product)),
      0
    );
  };

  const ShowCheckout = () => {
    const [firstName, setFirstName] = useState(""); // State to store first name
    const [phoneNumber, setPhoneNumber] = useState(""); // State to store phone number

    const firstNameRef = useRef(null);
    const phoneRef = useRef(null);
    const buy = async (event) => {
      event.preventDefault();
      const form = event.target;
      if (!form.checkValidity()) {
        event.stopPropagation();
        form.classList.add("was-validated");
        return;
      }
      try {
        const productsInfo = products.map((product) => ({
          id: product.id,
          quantity: product.qty,
          dimension: product.selectedDimension,
        }));
        await fetch("https://ezithrds.uz/api/order", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            products: productsInfo,
            full_name: firstName,
            phone_number: phoneNumber,
          }),
        });
        clearCartHandler();
        setOrderPlaced(true); // Set orderPlaced to true
      } catch (e) {
        alert(t("orderError"));
      }
    };

    return (
      <>
        <div className="container py-5">
          <div className="row my-4">
            <div className="col-md-5 col-lg-4 order-md-last">
              <div className="card mb-4">
                <div className="card-header py-3 bg-light">
                  <h5 className="mb-0">{t("mahsulot")}</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      {t("product")} (
                      {products.reduce((acc, p) => (acc += p.qty), 0)})
                      <span>{calculateTotalPrice()}.00 UZS</span>
                    </li>
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center px-0"
                      style={{ opacity: 0 }}
                    >
                      {t("deliver")}
                      <span>0 UZS</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px=0 mb-3">
                      <div>
                        <strong>{t("jami")}</strong>
                      </div>
                      <span>
                        <strong>{calculateTotalPrice()}.00 UZS</strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-8">
              <div className="card mb-4">
                <div className="card-header py-3">
                  <h4 className="mb-0">{t("buyurtma")}</h4>
                </div>
                <div className="card-body">
                  <form className="needs-validation" onSubmit={buy} noValidate>
                    <div className="row g-3">
                      <div className="col-sm-6 my-1">
                        <label htmlFor="firstName" className="form-label">
                          {t("fish")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder=""
                          required
                        />
                        <div className="invalid-feedback">
                          {t("personRequired")}
                        </div>
                      </div>

                      <div className="col-sm-6 my-1">
                        <label htmlFor="phone" className="form-label">
                          {t("phone")}:
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="+998-XX-XXX-XXXX"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                        />
                        <div className="invalid-feedback">
                          {t("phoneRequired")}
                        </div>
                      </div>
                    </div>
                    <hr className="my-4" />
                    <button className="w-100 btn btn-primary" type="submit">
                      {t("buy")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3" id="empty_cart">
        {orderPlaced ? (
          <div class="col-md-12 py-5 mt-5 bg-light text-center">
            <h4 class="p-3 display-5">{t("noitems")}</h4>
            <a class="btn btn-outline-dark mx-4" href="/">
              <i class="fa fa-arrow-left"></i>
              {t("continue")}
            </a>
          </div>
        ) : products.length ? (
          <ShowCheckout />
        ) : (
          <EmptyCart />
        )}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
