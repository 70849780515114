import React from 'react'
import { Footer, Main, Navbar, Product } from "../components"
import { useTranslation } from 'react-i18next'

const Products = () => {
  const {t}=useTranslation();
  return (
    <>
      <Navbar />
      <Main/>
      <div className="breadcrumb-block style-shared">
        <div className="breadcrumb-main overflow-hidden">
          <div className="container lg:pt-[54px]  pb-10 relative">
            <div className="main-content w-full h-full flex flex-col items-center justify-center relative z-[1]">
              <div className="text-content">
                <div className="heading2 text-center">{t('collections')}</div>
                <div className="link flex items-center justify-center gap-1 caption1 mt-3">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Product />
      <Footer />
    </>
  )
}

export default Products