import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FacebookIcon from '../assets/css/icomoon/mingcute--facebook-line.svg';
import InstagramIcon from '../assets/css/icomoon/ri--instagram-line.svg';
import TelegramIcon from '../assets/css/icomoon/ri--telegram-fill.svg';
import SearchIcon from '../assets/css/icomoon/ph--magnifying-glass.svg';
import BagIcon from '../assets/css/icomoon/ph--handbag.svg';
import LogoTOP from '../assets/images/newlogo.svg';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector';
import ModalSearch from '../pages/ModalSearch';

const Navbar = () => {
    const { t } = useTranslation();
    const state = useSelector(state => state.handleCart);
    const [langFunc, setLangFunc] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState('English');

    const handleLanguageClick = (language) => {
        setActiveLanguage(language);
        setLangFunc(false);
    };

    const toggleLangFunc = () => {
        setLangFunc(prevLangFunc => !prevLangFunc);
    };

    // Search functionality
    const [search, setSearch] = useState(false);
    const searchElement = () => {
        setSearch(prevSearch => !prevSearch);
    };
    const closeModal = () => {
        setSearch(prevSearch => !prevSearch);
    };

    // Scroll detection for header background change
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Animation for cart item addition
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (state.length > 0) {
            setAnimate(true);
            const timer = setTimeout(() => {
                setAnimate(false);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [state.length]);

    return (
        <>
            <div id="top-nav" className="top-nav style-one bg-black md:h-[44px] h-[30px]">
                <div className="container mx-auto h-full">
                    <div className="top-nav-main flex justify-between h-full">
                        <div className="left-content flex items-center gap-5">
                            <LanguageSelector />
                        </div>
                        <div className="right-content flex items-center gap-5" id='social_items'>
                            <a href="tel:+998 33 358-29-29" target="_blank" rel="noopener noreferrer">
                                {t('call')}: +998 33 358-29-29
                            </a>
                            <a href="https://www.instagram.com/ezithrds.wear?igsh=c2UwMzFodjI1Y3Jl&utm_source=qr" target="_blank" rel="noopener noreferrer">
                                <img src={InstagramIcon} alt="instagram" />
                            </a>
                            <a href="https://t.me/EZITHRDSwear" target="_blank" rel="noopener noreferrer">
                                <img src={TelegramIcon} alt="telegram" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* --- */}
            <div id="header" className="relative w-full">
                <div className={`header-menu style-one absolute top-0 left-0 right-0 w-full md:h-[74px] h-[56px] bg-transparent ${isScrolled ? 'fixed bg-wh' : ''}`}>
                    <div className="container mx-auto h-full">
                        <div className="header-main flex justify-between h-full">
                            <div className="menu-mobile-icon lg:hidden flex items-center">
                                <i className="icon-category text-2xl"></i>
                            </div>
                            <div className="left flex items-center gap-16">
                                <Link to={'/'}>
                                    <div>
                                        <img src={LogoTOP} alt="" className='logoMain' />
                                    </div>
                                </Link>
                                <div className="menu-main h-full max-lg:hidden">
                                    <ul className="flex items-center gap-8 h-full menu_items">
                                        <li className="h-full relative">
                                            <Link to={'/'} className="text-button-uppercase duration-300 h-full flex items-center justify-center">{t('home')}</Link>
                                        </li>
                                        <li className="h-full">
                                            <Link to={'/about'} className="text-button-uppercase duration-300 h-full flex items-center justify-center">{t('about')}</Link>
                                        </li>
                                        <li className="h-full">
                                            <Link to={'/product'} className="text-button-uppercase duration-300 h-full flex items-center justify-center">
                                                {t('product')}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="right flex gap-12">
                                <div className="search-icon flex items-center cursor-pointer relative">
                                    <i className="ph-bold ph-magnifying-glass text-2xl">
                                        <img src={SearchIcon} alt="search icon" onClick={searchElement} />
                                    </i>
                                    <div className="line absolute bg-line w-px h-6 -right-6"></div>
                                </div>
                                <div className="list-action flex items-center gap-4">
                                    <div className="cart-icon flex items-center relative cursor-pointer">
                                        <NavLink to="/cart">
                                            <i className="ph-bold ph-handbag text-2xl">
                                                <img src={BagIcon} alt="bag icon" />
                                            </i>
                                            <span className={`quantity cart-quantity absolute -right-1.5 -top-1.5 text-white bg-black rounded-full cart_counter ${animate ? 'bounce' : ''}`}>
                                                {state.length}
                                            </span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalSearch search={search} closeModal={closeModal}/>
        </>
    );
}

export default Navbar;
