// src/SliderComponent.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import slideImage1 from '../assets/images/new/11.webp';
import slider2 from '../assets/images/slider/1.jpg';
import slider3 from '../assets/images/slider/2.jpg';

const SliderComponent = () => {
    return (
        <div className="slider-banner-block">
            <div className="block-inner mygrid">
                <div className="left-slider">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={30}
                        pagination={{ clickable: true }}
                        loop={true}
                    >
                        <SwiperSlide>
                            <a href="/">
                                <img src={slider3} alt="Slide 1" />
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a href="/">
                                <img src={slider2} alt="Slide 2" />
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a href="/">
                                <img src={slideImage1} alt="Slide 3" />
                            </a>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};



export default SliderComponent;
