import React from "react";
import { useTranslation } from "react-i18next";
import discount from '../assets/discount.png';
import SliderComponent from './SliderComponent';
const Home = () => {
    const {t}=useTranslation()
  return (
    <>
      <div className="breadcrumb-block style-shared w-full">
                <div className="breadcrumb-main overflow-hidden">
                    <div className="pt-24  relative">
                        <div className="container">
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
};

export default Home;
