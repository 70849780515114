import React from "react";
import { Footer, Main, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { addCart, delCart } from "../redux/action";
import { Link } from "react-router-dom";
import PlusBtn from "../assets/css/icomoon/ph--plus.svg";
import MinusBtn from "../assets/css/icomoon/ph--minus.svg";

import { t } from "i18next";
import { useTranslation } from "react-i18next";
const ModalCart = () => {
    const products = useSelector((state) => state.handleCart);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const EmptyCart = () => {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12 py-5 bg-light text-center">
                        <h4 className="p-3 display-5">{t("cart")}</h4>
                        <Link to="/" className="btn  btn-outline-dark mx-4">
                            <i className="fa fa-arrow-left"></i> {t("continue")}
                        </Link>
                    </div>
                </div>
            </div>
        );
    };

    const addItem = (product) => {
        dispatch(addCart(product));
    };
    const removeItem = (product) => {
        dispatch(delCart(product));
    };
    const calculatePrice = ({ price, discount, qty }) => {
        const discounted = discount ? price * (1 - discount / 100) : price;
        const qytExist = qty ?? 1;
        return discounted * qytExist;
    };
    const calculateTotalPrice = () => {
        return products.reduce(
            (acc, product) => (acc += calculatePrice(product)),
            0
        );
    };
    const ShowCart = () => {
        return (
            <>
                <div className="cart-block">
                    <div className="container">
                        <div className="content-main flex justify-between ">
                            <div className="w-full">
                                <div className="list-product w-full sm:mt-7">
                                    <div className="w-full">
                                        <div className="list-product-main w-full">
                                            {products.map((product) => {
                                                return (
                                                    <div
                                                        key={product.id}
                                                        data-item="1" id="full_flex"
                                                        className="item flexwrap  flex pb-5 border-b border-line w-full"
                                                    >
                                                        <div id="full_flex">
                                                            <div className="flex items-center gap-6">
                                                                <div className="bg-img md:w-[100px] w-20 aspect-[3/4]">
                                                                    <img
                                                                        src={`https://ezithrds.uz/uploads/products/${product.photos ? product.photos[0] : ""
                                                                            }`}
                                                                        alt={product.nanme}
                                                                        className="w-full h-full object-cover rounded-lg"
                                                                    />
                                                                </div>
                                                                <div className="text-title">
                                                                    {product.name}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="cart__checkbox">
                                                            <div className="price flex items-center justify-center">
                                                                <div className="text-title text-center"></div>
                                                            </div>
                                                            <div className="flex items-center justify-center">
                                                                <div className="quantity-block bg-surface md:p-3 p-2 flex items-center justify-between rounded-lg border border-line md:w-[100px] flex-shrink-0 w-20">
                                                                    <i
                                                                        className="ph-bold ph-minus cursor-pointer text-base max-md:text-sm"
                                                                        onClick={() => {
                                                                            removeItem(product);
                                                                        }}
                                                                    >
                                                                        <img src={MinusBtn} alt="plus" />
                                                                    </i>
                                                                    <div className="text-button quantity">
                                                                        {product.qty ?? 1}
                                                                    </div>
                                                                    <i
                                                                        className="ph-bold ph-plus cursor-pointer text-base max-md:text-sm"
                                                                        onClick={() => {
                                                                            addItem(product);
                                                                        }}
                                                                    >
                                                                        <img src={PlusBtn} alt="plus" />
                                                                    </i>
                                                                </div>
                                                            </div>
                                                            <div className="w-full flex total-price items-center justify-center">
                                                                <div className="text-title flex">
                                                                    <p className="clean_element">
                                                                        <span>{calculatePrice(product)}</span>
                                                                        <span> {t("priceName")}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="new_right">
                                            <div className="wrapper_btn">
                                                <Link
                                                    className="checkout-btn button-main text-center"
                                                    to={"/checkout"}
                                                >
                                                    {t("buy")}
                                                </Link>
                                            </div>
                                            <div className="wr">
                                                <strong> {t("jami")}:</strong>
                                                <span className="total-cart">
                                                    {calculateTotalPrice()} {t("priceName")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {/* <Navbar /> */}
            {/* <Main /> */}
            <div className="container my-3 py-3" id="empty_cart">
                <h1 className="text-center"></h1>
                {products.length > 0 ? <ShowCart /> : <EmptyCart />}
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default ModalCart;
