import React from "react";
import del1 from "../assets/images/new/m1.svg";
import del2 from "../assets/images/new/m4.svg";
import del3 from "../assets/images/new/m6.svg";
import { useTranslation } from "react-i18next";
export default function Delivery() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container_90 footer_policy">
        <div className="row justify-content-center">
          <div className="content-policy d-flex align-items-center col-xl-3 col-lg-4 col-md-4  col-xs-12 mb-xs-30">
            <div className="policy-icon">
              <img
                className="lazyloaded"
                style={{ width: "100px" }}
                alt="Icon Policy"
                src={del1}
              />
            </div>
            <div className="policy-info">
              <div className="title-policy">
                <strong>{t("dl1")}</strong>
                <br />
                {t("dl2")}
              </div>
            </div>
          </div>
          <div className="content-policy d-flex align-items-center col-xl-3 col-lg-4 col-md-4  col-xs-12 mb-xs-30">
            <div className="policy-icon">
              <img
                className="lazyloaded"
                style={{ width: "100px" }}
                alt="Icon Policy"
                src={del2}
              />
            </div>
            <div className="policy-info">
              <div className="title-policy">
                <strong>{t("dl3")}</strong>
                <br />
                <a href="https://t.me/EZITHRDSwear" className="text-black">
                  {t("dd1")}
                </a>
                <a
                  className="text-black"
                  href="https://www.instagram.com/ezithrds.wear?igsh=c2UwMzFodjI1Y3Jl&utm_source=qr"
                >
                  {t("dd2")}
                </a>
                {t("dl4")}
              </div>
            </div>
          </div>

          <div className="content-policy d-flex align-items-center col-xl-3 col-lg-4 col-md-4  col-xs-12 mb-xs-30">
            <div className="policy-icon">
              <img
                className=" lazyloaded"
                alt="Icon Policy"
                src={del3}
                style={{ width: "100px" }}
              />
            </div>
            <div className="policy-info">
              <div className="title-policy">
                <>{t("dl5")}</>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
