import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '../assets/css/icomoon/ph--magnifying-glass.svg';

export default function ModalSearch({ search, closeModal }) {
    const [searchQuery, setSearchQuery] = useState('');
    const modalRef = useRef(null);

    const handleSearchInput = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        console.log('Searching for:', searchQuery);
        closeModal();
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal();
        }
    };

    useEffect(() => {
        if (search) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [search]);

    return (
        <>
            <div className={`modal-search-block ${search ? 'open-search' : ''}`}>
                <div className={`modal-search-main md:p-10 p-6 rounded-[32px] ${search ? 'open-search' : ""}`} ref={modalRef}>
                    <form onSubmit={handleSearchSubmit}>
                        <div className="form-search relative w-full flex gap-1">
                            <i className="ph ph-magnifying-glass absolute heading5 right-6 top-1/2 -translate-y-1/2 cursor-pointer"></i>
                            <input 
                                value={searchQuery} 
                                type="text" 
                                onChange={handleSearchInput} 
                                placeholder="Searching..." 
                                className="text-button-lg h-14 rounded-2xl border border-line w-full pl-6 pr-12" 
                            />
                            <img src={SearchIcon} alt="search" style={{ width: '25px' }} onClick={closeModal}/>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
