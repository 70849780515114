import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addCart } from "../redux/action";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import SliderComponent from "./SliderComponent";
import i18n from "i18next";
import OrderList from "../pages/OrderList";

const Products = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(12); // Adjust the page size as needed
  const [pageCount, setPageCount] = useState(1); // Default to 1 page
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current location

  const addProduct = (product) => {
    dispatch(addCart(product));
    setOrder((prevOrder) => !prevOrder);
  };
  // Search functionality
  const [order, setOrder] = useState(false);
  const orderElement = () => {
    setOrder((prevOrder) => !prevOrder);
  };
  const closeModal = () => {
    setOrder((prevOrder) => !prevOrder);
  };

  const getProducts = async (page) => {
    setLoading(true);
    const currentLanguage = i18n.language;
    const response = await fetch(
      `https://ezithrds.uz/api/products/all?lang=${currentLanguage}&pageIndex=${page}&pageSize=${pageSize}`
    );
    const data = await response.json();
    const { products, pageCount } = data;
    if (products?.length) {
      setData(products);
      setPageCount(pageCount);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts(pageIndex);
  }, [i18n.language, pageIndex]);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        {/* Add more skeleton loaders as needed */}
      </>
    );
  };

  const calculateDiscount = (price, discount) => {
    return price * (1 - discount / 100);
  };

  const ShowProducts = () => {
    return (
      <>
        {location.pathname === "/" && <SliderComponent />}{" "}
        {/* Render SliderComponent only on the home page */}
        <div className="shop-product">
          <div className="container">
            <div className="list-product-block style-grid relative">
              <div
                className="list-product hide-product-sold grid sm:grid-cols-3 grid-cols-2 sm:gap-[30px] gap-[20px] mt-7 lg:grid-cols-4"
                data-item="12"
              >
                {data.map((product) => (
                  <div
                    data-item="1"
                    id={product.id}
                    key={product.id}
                    className="product-item grid-type"
                  >
                    <div
                      className="product-main cursor-pointer block"
                      data-item="1"
                      key={product.id}
                    >
                      <Link to={"/product/" + product.id} className="buy_btn">
                        <div className="product-thumb bg-white relative overflow-hidden">
                          <div className="product-img w-full h-full aspect-[3/4]">
                            <img
                              key="0"
                              className="w-full h-full object-cover duration-700"
                              src={`https://ezithrds.uz/uploads/products/${
                                product.photos ? product.photos[0] : ""
                              }`}
                              alt="img"
                            />
                            <img
                              key="1"
                              className="w-full h-full object-cover duration-700"
                              src={`https://ezithrds.uz/uploads/products/${
                                product.photos
                                  ? product.photos[
                                      product.photos.length > 1 ? 1 : 0
                                    ]
                                  : ""
                              }`}
                              alt="img"
                            />
                          </div>
                        </div>
                      </Link>

                      <div className="product-inhtml mt-4 lg:mb-7">
                        <div
                          className="product-name text-title duration-300"
                          id="new_product_title"
                        >
                          {product.name}
                        </div>
                        <div className="clearlP">
                          <p className="mty">
                            <span className="clean_style">{t("country")}</span>:{" "}
                            <span className="text-sm">{product.country}</span>
                          </p>
                          <p className="mty">
                            <span className="clean_style">{t("made")}</span>:{" "}
                            <span className="text-sm">{product.made_with}</span>
                          </p>
                          <p className="mty">
                            <span className="clean_style">
                              {t("dimensions")}
                            </span>
                            :{" "}
                            <span className="text-sm">
                              {product.dimensions
                                .map((d) => {
                                  return d.value;
                                })
                                .join(",")}
                            </span>
                          </p>
                        </div>
                        <div className="product-price-block flex justify-content-between  items-center flex-wrap my-1 duration-300 relative z-[1]">
                          <div className="block">
                            <div className="product-price text-title">
                              {product.discount ? (
                                <del>
                                  {product.price} {t("priceName")}{" "}
                                </del>
                              ) : (
                                <div>
                                  {product.price} {t("priceName")}{" "}
                                </div>
                              )}
                            </div>
                            {product.discount ? (
                              <div className="product-origin-price caption1 text-secondary2">
                                {calculateDiscount(
                                  product.price,
                                  product.discount
                                )}{" "}
                                {t("priceName")}
                              </div>
                            ) : (
                              <div style={{ opacity: 0 }}>0</div>
                            )}
                          </div>
                        </div>
                        <div className="cart_btn">
                          <button
                            className="new_btn_cart"
                            onClick={() => addProduct(product)}
                          >
                            {t("buyy")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="list-pagination w-full flex items-center justify-center gap-4 mt-10">
                <button
                  disabled={pageIndex === 0}
                  onClick={() => setPageIndex(pageIndex - 1)}
                >
                  &lt;
                </button>
                {Array.from({ length: pageCount }, (_, index) => (
                  <button
                    key={index}
                    className={pageIndex === index ? "active" : ""}
                    onClick={() => setPageIndex(index)}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  disabled={pageIndex === pageCount - 1}
                  onClick={() => setPageIndex(pageIndex + 1)}
                >
                  &gt;
                </button>
              </div>
            </div>
          </div>
        </div>
        <OrderList search={order} closeModal={closeModal} />
      </>
    );
  };

  return (
    <>
      <div className="container my-1 py-1">
        <div className="row">
          <div className="col-12">
            {/* <h2 className="display-5 text-center">Latest Products</h2> */}
            {/* <hr /> */}
          </div>
        </div>
        <div className="row justify-content-center">
          {loading ? <Loading /> : <ShowProducts />}
        </div>
      </div>
    </>
  );
};

export default Products;
