import React from "react";
import logo from '../assets/images/newlogo.svg'
import Delivery from "./Delivery";
const Footer = () => {
  return (
    <>
    <Delivery/>
      <div id="footer" className="footer">
        <div className="footer-main bg-surface">
          <div className="container">
            <div className="content-footer md:py-[10px] py-4 flex justify-between flex-wrap gap-y-8">
            </div>
            <div className="footer-bottom py-3 flex items-center justify-center gap-5 max-lg:justify-center max-lg:flex-col border-t border-line">
              <div className="left flex items-center gap-8">
                <div className="copyright caption1 text-bold caption__footer text-secondary">2014 - {new Date().getFullYear()}</div>
                <div className="select-block flex items-center gap-5 max-md:hidden text-center">
                  <div className="caption__footer">
                    <img src={logo} alt="" className='logoMain' />
                  </div>
                </div>
              </div>
              <div className="right flex items-center gap-2">
                <div className="caption1 text-secondary"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
