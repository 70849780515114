import React, { useState } from "react";
import i18n from "./i18";
import DownIcon from "./assets/css/icomoon/ph--caret-down.svg";

const languages = [
  { code: "uz", name: "Uzbek" },
  { code: "ru", name: "Russian" },
  // { code: 'en', name: 'English' }
];

export default function LanguageSelector() {
  const [langFunc, setLangFunc] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(
    languages.find((lang) => lang.code === i18n.language)?.name || "Uzbek"
  );

  const changeLanguage = (code, name) => {
    i18n.changeLanguage(code);
    setActiveLanguage(name);
    setLangFunc(false);
  };

  const toggleLangFunc = () => {
    setLangFunc((prevLangFunc) => !prevLangFunc);
  };

  const handleLanguageClick = () => {
    alert(activeLanguage);
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      <div className="choose-type choose-language flex items-center gap-1.5">
        <div className="select relative">
          <p
            className="selected flex caption2 lang_resp text-white p_fs"
            onClick={toggleLangFunc}
          >
            {activeLanguage}
            <i className="ph ph-caret-down text-xs text-white">
              <img src={DownIcon} alt="downicon" />
            </i>
          </p>
          <ul
            className={
              langFunc
                ? "list-option active_lang bg-white default__menu "
                : "list-option bg-white default__menu_inactive"
            }
          >
            {languages.map((language) => (
              <li
                key={language.code}
                onClick={() => changeLanguage(language.code, language.name)}
                style={{ cursor: "pointer", padding: "5px 10px" }}
              >
                {language.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
