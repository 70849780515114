import React from 'react'
import { Footer, Navbar } from "../components";
import { useTranslation } from 'react-i18next';
import Delivery from '../components/Delivery';

const AboutPage = () => {
  const { t } = useTranslation()
  return (
    <>
      <Navbar />
      <div className="breadcrumb-block style-shared">
        <div className="breadcrumb-main overflow-hidden">
          <div className="container lg:pt-[134px] pt-24 pb-10 relative">
            <div className="main-content w-full h-full flex flex-col items-center justify-center relative z-[1]">
              <div className="text-content">
                <div className="heading2 text-center">{t('about')}</div>
                <div className="link flex items-center justify-center gap-1 caption1 mt-3">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about md:pt-20 pt-10">
        <div className="about-us-block">
          <div className="container">
            <div className="text flex items-center justify-center">
              <div className="content md:w-5/6 w-full">
                <div className="body1 text-center mt-1">
                  <p>{t('aboutInfo1')}</p>
                  <p>{t('aboutInfo2')}</p>
                  <p>{t('aboutInfo3')}</p>
                  <p>{t('aboutInfo4')}</p>
                  <p>{t('aboutInfo5')}</p>
                  <p>{t('aboutInfo6')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     <div className='about_f'>
     <Footer/>
     </div>
    </>
  )
}

export default AboutPage